:root{
    --container: 1200px;
    --container-lg: 1500px;

    --border-color: #d6e1e4;
    --border-color-dark: #383838;
    --default-text-color: #1e1e1e;
    --default-text-color-grey: #4e4e4e;

    --shadow-color: rgba(39,148,224,.3);

    --font-notosans: "NotoSansKR","Pretendard","Nanum Gothic","Apple SD Gothic Neo",Helvetica,Arial,sans-serif;
    --font-pre: "Pretendard","NotoSansKR","Nanum Gothic","Apple SD Gothic Neo",Helvetica,Arial,sans-serif;

    --input-small-height: 40px;
	--input-height: 44px;
	--input-large-height: 48px;
    --input-border-radius: 6px;
    --input-border-color: #d6e1e4;
    --input-border-hover: #d6e1e4;
    --input-border-focus: #383838;

    --primary-gra:to right, var(--v-primary-base), var(--v-secondary-base);

    --rounded-sm: 4px;
    --rounded: 10px;
    --rounded-md: 12px;
    --rounded-lg: 16px;
    --rounded-xl: 24px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    :root{
        --rounded-sm: 6px;
        --rounded: 12px;
        --rounded-md: 16px;
        --rounded-lg: 20px;
        --rounded-xl: 34px;
    }
}
@media (min-width:1024px){
	:root{
        --input-small-height: 50px;
		--input-height: 54px;
	    --input-large-height: 58px;
	}
}
@media (min-width:1200px){
}