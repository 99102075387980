
/* Pagination */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.pagination a{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 34px;
    height: 34px;
    font-size: 1.6rem;
    font-weight: 400;
    padding: 0 4px;
    margin:0 4px;
    border-radius: 4px;
    box-shadow: 1px 1px 6px var(--shadow-color);
    -webkit-transition: all ease-out .15s;
    transition: all ease-out .15s;
}
.pagination a:not(.pagination__current) {
    cursor: pointer;
}
.pagination .pagination__current{
    color:#fff !important;
    background-color: var(--v-primary-base);
}
// .pagination__backward, .pagination__prev, .pagination__next, .pagination__forward{
//     color: var(--v-primary-base) !important;
// }
.pagination__backward .v-icon,
.pagination__prev .v-icon,
.pagination__next .v-icon,
.pagination__forward .v-icon{
    color: inherit;
}
.pagination .pagination__prev{
    margin-right: 10px;
}
.pagination .pagination__next{
    margin-left: 10px;
}
.pagination .pagination__current {
    color: var(--v-primary-base);
    cursor: default;
}
// .pagination .pagination__current::after {
//     content: '';
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     -webkit-transform: translate(-50%, 0);
//     transform: translate(-50%, 0);
//     display: inline-block;
//     width: 10px;
//     height: 2px;
//     background-color: var(--v-primary-base);
// }
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .pagination{
        margin-top: 60px;
    }
}
